import React from 'react';
import { twMerge } from 'tailwind-merge';
import spriteUrl from '../../assets/sprite.svg';
import { iconNames } from './generated';

export type IconName = (typeof iconNames)[number];

export const Icon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & { name: IconName }
>(({ name, className, ...props }, ref) => {
  return (
    <svg
      className={twMerge('flex h-6 w-6 flex-shrink-0 items-center justify-center', className)}
      viewBox="0 0 24 24"
      {...props}
      ref={ref}>
      <use xlinkHref={`${spriteUrl}#${name}`} />
    </svg>
  );
});

export default Icon;
